import type { IMapCityTile } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import KeyFactsActivity from '@/core/components/key-facts-activity/key-facts-activity';
import { useClientUrl } from '@/core/features/app/app-atoms';
import MapTileWrapper from '@/features/map/map-components/map-tiles/map-tile-wrapper';
import { MapSpotTileTopLivePricesDesktop } from '@/features/map/map-components/map-tiles/spot-tile-desktop/map-spot-tile-top-live-prices/map-spot-tile-top-live-prices-desktop';
import useMapOverlayHistoryState from '@/features/map/map-container/use-map-overlay-history';
import { trackSpotResultClick } from '@/features/spot-results/spot-result-tracking';

type MapSpotTileProps = IMapCityTile;

export default function MapCityTileDesktop({
    image,
    keyFacts,
    linkUrl,
    spotName,
    subtitle,
    title,
    topLivePrices,
}: MapSpotTileProps) {
    const [clientUrl] = useClientUrl();
    const { closeOverlay } = useMapOverlayHistoryState();

    const handleOnTileClick = (event: React.MouseEvent<HTMLAnchorElement, Event>) => {
        trackSpotResultClick({ activitySpot: spotName, city: spotName, isMap: true });

        //checking if the click happened on the same path (if that is the case, the map should be closed)
        if (linkUrl === clientUrl) {
            event.preventDefault();
            closeOverlay();
        }
    };

    return (
        <div
            className={'height-100 flex'}
            data-qa-id={'qa-map-spot-tile-desktop'}
        >
            <MapTileWrapper
                cursorStyle={'pointer'}
                hasLivePrices={topLivePrices.length > 0}
                height={'197px'}
                image={image}
                isDesktopView={true}
                linkType={'internal'}
                linkUrl={linkUrl}
                onTileLinkClick={handleOnTileClick}
                subtitle={subtitle}
                title={title}
                titleTruncation={'line-clamp-1'}
            >
                <div className={'padding-15 padding-top-20'}>
                    <KeyFactsActivity
                        fontSize={14}
                        keyFacts={keyFacts}
                    />
                </div>
            </MapTileWrapper>

            {topLivePrices.length > 0 && (
                <div
                    className={'flex grow-1'}
                    style={{
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        alignItems: 'stretch',
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        minWidth: '85px',
                    }}
                >
                    <MapSpotTileTopLivePricesDesktop
                        activitySpotName={spotName}
                        livePrices={topLivePrices}
                        spotName={spotName}
                    />
                </div>
            )}
        </div>
    );
}
