/* eslint-disable fp/no-mutation */
import type {
    GoogleMapInstance,
    GoogleMapLatLng,
    GoogleMapLatLngLiteral,
} from '@/features/map/google-map/google-map-types';
import type { IMapPin } from '@/features/map/map-data-v5/map-types';

import React from 'react';
import { createPortal } from 'react-dom';

import { logger } from '@/core/features/logger/logger';

type OverlayProps = React.PropsWithChildren<{
    disableHighlighting?: boolean;
    isActive?: boolean;
    map: GoogleMapInstance;
    /* Offset is needed for absolute positioning.
    This way we prevent the pins from moving.  */
    offsetTop: number;
    onMarkerClick?: (event: MouseEvent) => void;
    position: GoogleMapLatLng | GoogleMapLatLngLiteral;
    zIndex?: IMapPin['zPosition'];
}>;

const zIndexOnHighlight = '999999';

export default function CustomGoogleOverlay({
    children,
    disableHighlighting,
    isActive,
    map,
    offsetTop,
    onMarkerClick = () => {},
    position,
    zIndex,
}: OverlayProps) {
    const containerRef = React.useRef<HTMLDivElement>(document.createElement('div'));

    React.useEffect(() => {
        containerRef.current.style.position = 'absolute';
        containerRef.current.style.top = `${-offsetTop}px`;
        containerRef.current.style.pointerEvents = 'none';
        containerRef.current.style.zIndex = zIndexOnHighlight;
        containerRef.current.onmouseover = () => {
            if (!disableHighlighting) {
                containerRef.current.style.zIndex = zIndexOnHighlight;
            }
        };
        containerRef.current.onmouseleave = () => {
            if (!disableHighlighting && zIndex !== undefined) {
                containerRef.current.style.zIndex = `${zIndex}`;
            }
        };
    }, [disableHighlighting, offsetTop, zIndex]);

    const overlay = React.useMemo(() => {
        return createOverlay(containerRef.current, position);
    }, [position]);

    React.useEffect(() => {
        if (!overlay) {
            return;
        }
        overlay.setMap(map);
        return () => overlay.setMap(null);
    }, [map, overlay]);

    React.useEffect(() => {
        containerRef.current.style.zIndex = isActive || zIndex === undefined ? zIndexOnHighlight : `${zIndex}`;
        google.maps.OverlayView.preventMapHitsFrom(containerRef.current);
        containerRef.current.addEventListener('click', onMarkerClick);
    }, [isActive, onMarkerClick, zIndex]);

    return createPortal(children, containerRef.current);
}

/* eslint-disable fp/no-this */
/* eslint-disable fp/no-class */
const createOverlay = (
    container: HTMLElement,
    position: GoogleMapLatLng | GoogleMapLatLngLiteral,
): google.maps.OverlayView | null => {
    if (!google) {
        return null;
    }
    class Overlay extends google.maps.OverlayView {
        container: HTMLElement;
        position: GoogleMapLatLng | GoogleMapLatLngLiteral;

        constructor(container: HTMLElement, position: GoogleMapLatLng | GoogleMapLatLngLiteral) {
            super();
            this.container = container;
            this.position = position;
        }

        draw(): void {
            const projection = this.getProjection();
            const point = projection.fromLatLngToDivPixel(this.position);

            if (point === null) {
                return;
            }

            this.container.style.transform = `translate(${Math.round(point.x)}px, ${Math.round(point.y)}px)`;
        }

        onAdd(): void {
            const pane = this.getPanes()?.['floatPane'];
            pane?.appendChild(this.container);
        }

        onRemove(): void {
            try {
                if (this.container.parentNode !== null) {
                    this.container.parentNode.removeChild(this.container);
                }
            } catch (error) {
                logger.error(`Error in map onRemove: ${error}`);
            }
        }
    }

    return new Overlay(container, position);
};
