import type { BadgeType } from '@/core/components/badges/badge-type';
import type { CtaListProps } from '@/core/components/cta-list/cta-list';
import type { LinkProps } from '@/core/features/link/link';

import { Badge } from '@/core/features';
import { CtaList, HtmlTextWrapper, IconImage, Modal } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';

import styles from './cta-list-overlay.module.scss';

export type CtaListOverlayProps = CtaListProps & {
    badge?: BadgeType | null;
    disableSafeAreaInsetBottom?: boolean;
    htmlText: null | string;
    htmlTitle: string;
    linkTarget?: LinkProps['target'];
    onClose: () => void;
    textIconUrl: null | string;
};

export default function CtaListOverlay({
    badge,
    ctaList,
    disableSafeAreaInsetBottom = false,
    fontSize = 16,
    fontWeight = 'bold',
    htmlText,
    htmlTitle,
    iconSize = 24,
    linkTarget,
    linkType,
    onClick,
    onClose,
    subtitleFontSize,
    textIconUrl,
}: CtaListOverlayProps) {
    const { isDesktopView } = useDeviceoutput();

    return (
        <Modal
            disableSafeAreaInsetBottom={disableSafeAreaInsetBottom}
            maxWidth={isDesktopView ? 483 : undefined}
            onClose={onClose}
            padding={isDesktopView ? 'padding-30' : 'padding-20'}
            position={isDesktopView ? 'default' : 'bottom'}
        >
            <div className={styles.container}>
                <div>
                    {badge && (
                        <div className={'flex-start-center'}>
                            <Badge
                                badge={badge}
                                fontSize={14}
                                padding={'padding-0'}
                            />
                        </div>
                    )}
                    <HtmlTextWrapper
                        className={styles.title}
                        htmlText={htmlTitle}
                    />
                    <div className={'flex-start-center margin-top-10 gap-10'}>
                        {textIconUrl && (
                            <IconImage
                                alt={''}
                                height={30}
                                url={textIconUrl}
                                width={30}
                            />
                        )}
                        {htmlText && (
                            <HtmlTextWrapper
                                className={styles.htmlText}
                                htmlText={htmlText}
                            />
                        )}
                    </div>
                </div>
                <CtaList
                    ctaList={ctaList}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    iconSize={iconSize}
                    linkTarget={linkTarget}
                    linkType={linkType}
                    onClick={onClick}
                    subtitleFontSize={subtitleFontSize}
                />
            </div>
        </Modal>
    );
}
