import type { MapType } from '@/features/map/map-components/map-layer-switch/map-layer-switch';

import React from 'react';

import { useIsIos, useIsMac } from '@/core/features/app/app-atoms';
import { getClientSideDeviceInfo } from '@/core/features/device/device-service';
import { useSetAtom } from '@/core/features/store/atom-store';
import { googleMapInstanceAtom, useGoogleMapInstance } from '@/features/map/google-map/google-map-state';

import styles from './google-map.module.scss';

export type GoogleMapProps = {
    children?: React.ReactNode;
    isBottomSheet?: boolean;
    mapType: MapType;
};

const GOOGLE_FONT_BASE_URL = 'https://fonts.googleapis.com/css';
const { deviceMemory, hasWebGlSupport, isDataSaverModeEnabled } = getClientSideDeviceInfo();

const GoogleMap_ = ({ children, isBottomSheet = false, mapType }: GoogleMapProps) => {
    const mapDivRef = React.useRef<HTMLDivElement>(null);
    const insertBeforeRef = React.useRef<Node['insertBefore'] | null>(null);
    const googleMapInstance = useGoogleMapInstance();
    const setGoogleMapInstance = useSetAtom(googleMapInstanceAtom);
    const isIos = useIsIos();
    const isMac = useIsMac();
    const hasVectorMapSupport = (deviceMemory >= 8 || isIos || isMac) && !isDataSaverModeEnabled && hasWebGlSupport;

    // keep for debugging
    // React.useEffect(() => {
    //     if (!googleMapInstance) {
    //         return;
    //     }
    //     window.googleMapInstance = googleMapInstance;
    // }, [googleMapInstance]);

    // Prevent Google Maps from inserting unnecessary font links into the head
    React.useEffect(() => {
        const head = document.querySelector('head');

        if (!head) {
            return;
        }

        insertBeforeRef.current = head.insertBefore;

        // eslint-disable-next-line fp/no-mutation
        head.insertBefore = (node, child) => {
            const href = 'href' in node && typeof node.href === 'string' ? node.href : null;

            if (href && href.indexOf(GOOGLE_FONT_BASE_URL) === 0) {
                return node;
            }

            insertBeforeRef.current?.call(head, node, child);
            return node;
        };

        return () => {
            if (insertBeforeRef.current) {
                // eslint-disable-next-line fp/no-mutation
                head.insertBefore = insertBeforeRef.current;
            }
        };
    }, []);

    React.useEffect(() => {
        return () => {
            setGoogleMapInstance(null);
        };
    }, [setGoogleMapInstance]);

    React.useEffect(() => {
        if (mapDivRef.current && googleMapInstance === null) {
            const googleMap = new window.google.maps.Map(mapDivRef.current, {
                cameraControl: false,
                center: { lat: 48, lng: 8 },
                clickableIcons: false,
                fullscreenControl: false,
                gestureHandling: 'greedy',
                mapId: hasVectorMapSupport ? '8b0221f0afdb8dbe' : 'f373c617ac913536',
                mapTypeControl: false,
                mapTypeId: mapType,
                maxZoom: 19,
                minZoom: 2,
                renderingType: hasVectorMapSupport
                    ? google.maps.RenderingType.VECTOR
                    : google.maps.RenderingType.RASTER,
                restriction: { latLngBounds: { east: 180, north: 85, south: -85, west: -180 } },
                scaleControl: true,
                streetViewControl: false,
                zoomControl: false,
            });
            setGoogleMapInstance(googleMap);
        }
    }, [mapDivRef, googleMapInstance, children, mapType, setGoogleMapInstance, hasVectorMapSupport]);

    return (
        <div
            className={`flex height-100 ${!isBottomSheet ? 'safe-area-bottom' : ''} ${styles.mapWrapper}`}
            data-qa-id={'qa-google-map-wrapper'}
        >
            <div className={'width-100 height-100'}>
                <div
                    className={`absolute-full ${styles.googleMap}`}
                    ref={mapDivRef}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default React.memo(GoogleMap_);
