import React from 'react';

import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import { useMapActivePoiKeyState, useMapHoveredPoiKey, useMapTilesState } from '@/features/map/map-container/map-state';

export default function useMapPlaceRoutePreview() {
    const googleMapInstance = useGoogleMapInstance();
    const polyline = React.useRef<google.maps.Polyline | null>(null);
    const mapTiles = useMapTilesState();
    const activePoiKey = useMapActivePoiKeyState();
    const [hoveredPoiKey] = useMapHoveredPoiKey();

    const poiKey = activePoiKey ?? hoveredPoiKey;

    const route = React.useMemo(() => {
        if (!poiKey || !mapTiles) {
            return null;
        }

        const mapTile = mapTiles[poiKey];

        if (!mapTile || mapTile.type !== 'place') {
            return null;
        }

        return mapTile.route;
    }, [mapTiles, poiKey]);

    React.useEffect(() => {
        if (!poiKey || !route || !googleMapInstance) {
            return;
        }

        polyline.current = new google.maps.Polyline({
            geodesic: true,
            map: googleMapInstance,
            path: route.points,
            strokeColor: route.color,
            strokeOpacity: 1,
            strokeWeight: route.width,
            zIndex: 1, // It's the lowest value a marker position can be and one above multi-polygons
        });

        return () => {
            polyline.current?.setMap(null);
            polyline.current = null;
        };
    }, [googleMapInstance, poiKey, route]);
}
