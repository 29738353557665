import type { CtaListOnClickInterface } from '@/core/components/cta-list/cta-list';
import type { OnClickEvent } from '@/core/types/react-overrides';
import type { AccommodationOverlayType } from '@/features/accommodation-tiles/accommodation-type';
import type { MapAccommodationTileDesktopProps } from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation-desktop/map-accommodation-tile-desktop';

import React from 'react';

import { WindowCloseThinIcon } from '@/assets/icons/window-close-thin';
import BadgeDesktop from '@/core/components/badges/badge-desktop/badge-desktop';
import { CtaList, HtmlTextWrapper } from '@/core/features';
import color from '@/core/features/styles/color';

type MapAccommodationTileOverlayProps = Pick<MapAccommodationTileDesktopProps, 'accommodationTypeBadge' | 'name'> & {
    onClose: (event: OnClickEvent) => void;
    onOverlayCtaClick: CtaListOnClickInterface;
    overlay: AccommodationOverlayType | null;
};

export default function MapAccommodationTileOverlay({
    name,
    onClose,
    onOverlayCtaClick,
    overlay,
}: MapAccommodationTileOverlayProps) {
    if (!overlay) {
        return null;
    }

    return (
        <div
            className={'flex-column height-100 padding-15 gap-20 justify-center'}
            onClick={onClose}
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.97)' }}
        >
            <div className={'flex-column gap-5'}>
                {overlay.accommodationTypeBadge && (
                    <BadgeDesktop
                        badge={overlay.accommodationTypeBadge}
                        fontSize={'font-size-14'}
                        padding={'padding-0'}
                    />
                )}
                <HtmlTextWrapper
                    className={'font-size-18 bold'}
                    htmlText={name}
                />
                {overlay.htmlText && (
                    <HtmlTextWrapper
                        className={'font-size-14'}
                        htmlText={overlay.htmlText}
                    />
                )}
            </div>
            <CtaList
                ctaList={overlay.ctaList}
                fontSize={18}
                fontWeight={'normal'}
                iconSize={22}
                linkTarget={'_blank'}
                linkType={'vertical'}
                onClick={onOverlayCtaClick}
                subtitleFontSize={'font-size-14'}
            />
            <WindowCloseThinIcon
                className={'absolute top-15 right-15 pointer'}
                color={color('mineShaft')}
                height={20}
                onClick={onClose}
                width={20}
            />
        </div>
    );
}
