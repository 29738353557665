import type {
    GoogleMapBounds,
    GoogleMapLatLng,
    GoogleMapLatLngLiteral,
} from '@/features/map/google-map/google-map-types';
import type { IMapMultiPolygon } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { useAtomValue } from '@/core/features/store/atom-store';
import { COLORS } from '@/core/features/styles/generated/colors';
import CustomGoogleOverlay from '@/features/map/google-map/google-map-custom-overlay';
import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import MapTooltip from '@/features/map/map-components/map-markers/map-marker-handler/map-tooltip/map-tooltip';
import MapTileSwitchDesktop from '@/features/map/map-components/map-tiles/map-tile-switch-desktop';
import {
    mapTilesStateAtom,
    useActiveMultiPolygons,
    useMapActivePoiKeyState,
    useMapDataActiveTileState,
} from '@/features/map/map-container/map-state';

type MultiPolygonMarkerProps = {
    isActivePoi: boolean;
    marker: IMapMultiPolygon;
    markerKey: string;
    onPoiClick: (event: React.MouseEvent, poiKey: string) => void;
};

export default function MultiPolygonMarker({ isActivePoi, marker, markerKey, onPoiClick }: MultiPolygonMarkerProps) {
    const googleMap = useGoogleMapInstance();
    const mapTiles = useAtomValue(mapTilesStateAtom);
    const activePoiKey = useMapActivePoiKeyState();
    const { addActiveMultiPolygonMarker, removeActiveMultiPolygonMarker } = useActiveMultiPolygons();
    const [viewportBounds, setViewportBounds] = React.useState<GoogleMapBounds | null>(null);
    const polygons = React.useRef<google.maps.Polygon[] | null>(null);
    const polygonOutlines = React.useRef<google.maps.Polyline[] | null>(null);
    const [isPolygonHovered, setPolygonHovered] = React.useState(false);

    const { isDesktopView } = useDeviceoutput();
    const mapDataActiveTileState = useMapDataActiveTileState();
    const markerRef = React.useRef<HTMLDivElement | null>(null);
    const overlayRef = React.useRef<HTMLDivElement | null>(null);

    const isHighlighted = isActivePoi || isPolygonHovered || (marker.displayType === 'border' && !activePoiKey);

    const hasTile = React.useMemo(() => {
        if (!mapTiles) {
            return false;
        }

        return Object.keys(mapTiles).some((poiKey) => poiKey.includes(marker.poiId.toString()));
    }, [mapTiles, marker.poiId]);

    const tilePosition: GoogleMapLatLng | GoogleMapLatLngLiteral | null = React.useMemo(() => {
        if (!googleMap || !viewportBounds) {
            return null;
        }

        const polygonPointsInsideViewport = marker.multiPolygon.flatMap(
            (polygon) => polygon[0]?.filter((point) => viewportBounds.contains(point)) ?? [],
        );

        const polygonBounds = new google.maps.LatLngBounds();
        marker.multiPolygon.forEach((polygon) => {
            polygon[0]?.forEach((point) => polygonBounds.extend(point));
        });

        const viewportPointsInsidePolygon = [
            viewportBounds.getSouthWest(),
            viewportBounds.getNorthEast(),
            { lat: viewportBounds.getSouthWest().lat(), lng: viewportBounds.getNorthEast().lng() },
            { lat: viewportBounds.getNorthEast().lat(), lng: viewportBounds.getSouthWest().lng() },
        ].filter((point) => polygonBounds.contains(point));

        const polygonViewportBounds = new google.maps.LatLngBounds();
        polygonPointsInsideViewport.forEach((point) => polygonViewportBounds.extend(point));
        viewportPointsInsidePolygon.forEach((point) => polygonViewportBounds.extend(point));

        return polygonViewportBounds.getCenter();
    }, [viewportBounds, googleMap, marker.multiPolygon]);

    React.useEffect(() => {
        if (!googleMap) {
            return;
        }

        setViewportBounds(googleMap.getBounds() ?? null);
        const boundsChangedListener = googleMap.addListener('bounds_changed', () => {
            setViewportBounds(googleMap.getBounds() ?? null);
        });

        return () => google.maps.event.removeListener(boundsChangedListener);
    }, [googleMap]);

    React.useEffect(() => {
        if (isHighlighted) {
            addActiveMultiPolygonMarker(marker);
        } else {
            removeActiveMultiPolygonMarker(marker.poiId);
        }

        return () => removeActiveMultiPolygonMarker(marker.poiId);
    }, [addActiveMultiPolygonMarker, isHighlighted, marker, removeActiveMultiPolygonMarker]);

    React.useEffect(() => {
        if (!googleMap) {
            return;
        }

        if (hasTile) {
            // clickable invisible polygon (original)
            polygons.current = marker.multiPolygon.map((polygon) => {
                const mapPolygon = new google.maps.Polygon({
                    clickable: true,
                    fillOpacity: 0,
                    geodesic: true,
                    map: googleMap,
                    paths: polygon,
                    strokeOpacity: 0,
                    zIndex: marker.zPosition,
                });

                mapPolygon.addListener('click', (event: { domEvent: React.MouseEvent }) => {
                    onPoiClick(event.domEvent, markerKey);
                });

                if (isDesktopView) {
                    mapPolygon.addListener('mouseover', () => {
                        setPolygonHovered(true);
                    });

                    mapPolygon.addListener('mouseout', () => {
                        setPolygonHovered(false);
                    });
                }

                return mapPolygon;
            });
        }

        if (isHighlighted) {
            // Outlines have to be rendered separately because polygons only support solid stokes
            polygonOutlines.current = marker.multiPolygon.flatMap((polygon) =>
                polygon.flatMap((outline) => {
                    const closingPoint = outline[0];

                    if (!closingPoint) {
                        return [];
                    }

                    return new google.maps.Polyline({
                        geodesic: true,
                        // icons define dotted stoke
                        icons: [
                            {
                                icon: {
                                    path: 'M 0,-1 0,0',
                                    scale: 1,
                                    strokeOpacity: 1,
                                    strokeWeight: 1.6,
                                },
                                offset: '0',
                                repeat: '5px',
                            },
                        ],
                        map: googleMap,
                        path: [...outline, closingPoint],
                        strokeColor: COLORS.cinnabar,
                        strokeOpacity: 0,
                        zIndex: marker.zPosition,
                    });
                }),
            );
        }

        return () => {
            polygons.current?.forEach((polygon) => {
                polygon.setMap(null);
                // When moving the mouse too fast over the polygons, the mouseout listener is removed before it executes the handler
                // resulting in polygons to not get hidden
                setTimeout(() => google.maps.event.clearInstanceListeners(polygon), 200);
            });
            polygons.current = null;
            polygonOutlines.current?.forEach((outline) => outline.setMap(null));
            polygonOutlines.current = null;
        };
    }, [
        googleMap,
        hasTile,
        isDesktopView,
        isHighlighted,
        marker.multiPolygon,
        marker.zPosition,
        markerKey,
        onPoiClick,
    ]);

    if (!googleMap || !tilePosition || !isActivePoi || !isDesktopView) {
        return null;
    }

    return (
        <CustomGoogleOverlay
            map={googleMap}
            offsetTop={0}
            position={tilePosition}
        >
            <div ref={markerRef}></div>
            <MapTooltip
                anchorRef={markerRef}
                overlayRef={overlayRef}
                placements={['center', 'bottom', 'top', 'left', 'right']}
            >
                <div
                    data-qa-id={'qa-map-tile-container'}
                    ref={overlayRef}
                    style={{
                        width: mapDataActiveTileState?.type === 'accommodation' ? 486 : 'auto',
                    }}
                >
                    <MapTileSwitchDesktop activeTile={mapDataActiveTileState} />
                </div>
            </MapTooltip>
        </CustomGoogleOverlay>
    );
}
