import type { MapAccommodationTileDesktopProps } from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation-desktop/map-accommodation-tile-desktop';

import React from 'react';

import BadgeDesktop from '@/core/components/badges/badge-desktop/badge-desktop';
import Badges from '@/core/components/badges/badges';
import { KeyFact, ShimmerBox } from '@/core/features';
import { bemModule, classnames } from '@/core/utils/bem-classname';
import AccommodationTileStarsDesktop from '@/features/accommodation-tiles/accommodation-tiles-v1-desktop/accommodation-tile-desktop/accommodation-tile-stars-desktop';

import styles from './map-accommodation-tile-content.module.scss';

const bem = bemModule(styles);

type MapAccommodationTileContentProps = Pick<
    MapAccommodationTileDesktopProps,
    'accommodationTypeBadge' | 'badges' | 'highlightedBadges' | 'keyfacts' | 'name' | 'offer' | 'rating' | 'stars'
> & { isLoading: boolean };

export function MapAccommodationTileContent({
    accommodationTypeBadge,
    badges,
    highlightedBadges,
    isLoading,
    keyfacts,
    name,
    offer,
    rating,
    stars,
}: MapAccommodationTileContentProps) {
    const hasRating = !!rating.countText;

    return (
        <div
            className={classnames('flex-column gap-10 padding-15 height-100 pointer', {
                'bg-color-foam': highlightedBadges.length > 0,
            })}
        >
            <div className={`flex-column ${styles.nameBadgeStarsWrapper}`}>
                <div className={'ellipsis font-size-16 bold'}>{name}</div>
                {accommodationTypeBadge && (
                    <div className={styles.badgeWrapper}>
                        <BadgeDesktop badge={accommodationTypeBadge} />
                    </div>
                )}
                {stars && stars.count > 0 && (
                    <AccommodationTileStarsDesktop
                        accommodationStars={stars}
                        tooltipText={stars?.htmlOverlayText}
                    />
                )}
            </div>
            <Badges
                badges={badges}
                marginBottom={10}
                wrap={'wrap'}
            />
            {keyfacts.length > 0 && (
                <div className={'flex-start-center margin-bottom-10 gap-10 wrap'}>
                    {keyfacts.map((keyFact, index) => {
                        const isLast = index + 1 === keyfacts.length;
                        return (
                            <div
                                className={'flex-start-center gap-10'}
                                key={keyFact.text}
                            >
                                <KeyFact
                                    fontSize={'font-size-12'}
                                    iconUrl={keyFact.iconUrl}
                                    text={keyFact.text}
                                />
                                {!isLast && <div className={'bullet'} />}
                            </div>
                        );
                    })}
                </div>
            )}
            <div className={'flex-column grow-1'}>
                <div className={bem(styles.mapAccommodationTileRatingContainer, { hasRating })}>
                    <span
                        className={`flex-center bold font-size-14 border-radius text-white ${styles.accommodationTileRating}`}
                    >
                        {rating.scoreText}
                    </span>
                    <div
                        className={classnames('ellipsis margin-top-0', {
                            'margin-top-2': hasRating,
                        })}
                    >
                        <div
                            className={classnames('ellipsis font-size-12', {
                                bold: hasRating,
                            })}
                        >
                            {rating.scoreDescription}
                        </div>
                        {hasRating && (
                            <div className={'${styles.accommodationTileRatingCount} ellipsis font-size-10'}>
                                {rating.countText}
                            </div>
                        )}
                    </div>
                </div>
                {!isLoading ? (
                    <div className={'flex-end flex-column text-end gap-5 grow-1'}>
                        <span className={'margin-top-10 font-size-12'}>{offer.offerDetails}</span>
                        {offer.savings && (
                            <span
                                className={`${styles.mapAccommodationTileDetailsSavings} font-size-12 text-persian-red line-through`}
                            >
                                {offer.savings}
                            </span>
                        )}
                        <span className={'font-size-24 block'}>
                            <span className={'margin-right-5 font-size-12'}>ab</span>
                            {offer.price}
                        </span>
                    </div>
                ) : (
                    <div
                        className={'flex-end flex-column gap-5 align-end grow-1'}
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ height: '57.5px' }}
                    >
                        <ShimmerBox
                            borderRadius={'3px'}
                            height={'16px'}
                            width={'100px'}
                        />
                        <ShimmerBox
                            borderRadius={'3px'}
                            height={'24px'}
                            width={'115px'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
