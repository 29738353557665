import { IS_SERVER } from '@/constants/env';
import logger from '@/core/features/logger/logger';

type NavigatorType = Navigator & {
    connection?: {
        saveData?: boolean;
    };
};

const WebGlInstance = (() => {
    let webGlRenderingContext: RenderingContext | null = null;
    let canvas: HTMLCanvasElement | null = null;

    return {
        getContext: (): RenderingContext | null => {
            if (!webGlRenderingContext) {
                // eslint-disable-next-line fp/no-mutation
                canvas = document.createElement('canvas');
                // eslint-disable-next-line fp/no-mutation
                webGlRenderingContext = canvas.getContext('webgl');
                if (!webGlRenderingContext) {
                    logger.warn('WebGL not supported.');
                }
            }
            return webGlRenderingContext;
        },
    };
})();

export const getClientSideDeviceInfo = (): {
    deviceMemory: number;
    hasWebGlSupport: boolean;
    isDataSaverModeEnabled: boolean;
} => {
    if (IS_SERVER) {
        return {
            deviceMemory: 0,
            hasWebGlSupport: false,
            isDataSaverModeEnabled: false,
        };
    }
    return {
        deviceMemory:
            'deviceMemory' in navigator && typeof navigator.deviceMemory === 'number' ? navigator.deviceMemory : 0,
        hasWebGlSupport: !!WebGlInstance.getContext(),
        isDataSaverModeEnabled: isInDataSaverMode(),
    };
};

const isInDataSaverMode = (): boolean => {
    const _navigator = navigator as NavigatorType;
    if (!('connection' in _navigator)) {
        return false;
    }
    return _navigator.connection?.saveData ?? false;
};
