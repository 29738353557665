import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import type { ZIndexClassName } from '@packages/styles/z-indexes';
// eslint-disable-next-line import-rules/disallow-paths
import type { TippyProps } from '@tippyjs/react';
import type { Instance } from 'tippy.js';

import React from 'react';

// eslint-disable-next-line import-rules/disallow-paths
import Tippy from '@tippyjs/react';

import { Z_INDEXES } from '@/core/features/styles/generated/z-index';
import TooltipContentBox from '@/core/features/tooltip/tooltip-content-box';

import styles from './tooltip.module.scss';

export type TippyConfig = TippyProps;

export type TooltipProps = React.PropsWithChildren<{
    animation?: boolean;
    ariaLabel?: string;
    className?: string;
    content: React.ReactNode;
    customZIndex?: ZIndexClassName;
    flip?: boolean;
    hasCloseButton?: boolean;
    isCloseable?: boolean;
    minWidth?: number;
    onClose?: () => void;
    onHide?: () => void;
    placementRef?: Element | React.RefObject<Element> | null;
    qaId?: string;
    strategy?: 'absolute' | 'fixed';
    tippyConfig?: TippyConfig;
    visible?: boolean;
}>;

export default function Tooltip({
    animation = true,
    ariaLabel,
    children,
    className,
    content,
    customZIndex,
    flip = true,
    hasCloseButton,
    isCloseable,
    minWidth,
    onClose,
    onHide,
    placementRef,
    qaId,
    strategy = 'fixed',
    tippyConfig,
    visible,
}: TooltipProps) {
    const [instance, setInstance] = React.useState<Instance>();
    const selfRef = React.useRef<HTMLDivElement>(null);

    const handleClose = () => {
        instance?.hide();
    };

    return (
        <div
            aria-label={ariaLabel}
            className={className}
            data-qa-id={qaId}
            ref={selfRef}
            role={'tooltip'}
        >
            <Tippy
                animation={animation}
                appendTo={selfRef.current ?? undefined}
                className={styles.tooltip}
                content={
                    <TooltipContentBox
                        content={content}
                        handleClose={onClose ? onClose : handleClose}
                        hasCloseButton={hasCloseButton}
                        isCloseable={isCloseable}
                        minWidth={minWidth}
                    />
                }
                delay={150}
                duration={0}
                interactive
                maxWidth={500}
                offset={[0, 0]}
                onClickOutside={onClose}
                onCreate={setInstance}
                onHide={onHide}
                placement={'auto'}
                popperOptions={{ modifiers: [{ enabled: flip, name: 'flip' }], strategy }}
                reference={placementRef}
                theme={'light'}
                visible={visible}
                zIndex={customZIndex ? Z_INDEXES[customZIndex] : Z_INDEXES['overlayTooltip']}
                {...tippyConfig}
                trigger={visible !== undefined ? undefined : (tippyConfig?.trigger ?? 'mouseenter focus')}
            >
                <div>{children}</div>
            </Tippy>
        </div>
    );
}
