/* eslint-disable no-inline-styles/no-inline-styles */
import type { VerticalToggleOptionMap } from '@/features/vertical-toggle/vertical-toggle-type';

import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import IconImage from '@/core/features/image-v2/icon-image';
import { historyReplaceDebounced } from '@/core/features/router/history';
import MapTooltip from '@/features/map/map-components/map-tooltip/map-tooltip';

type MapVerticalToggleProps = {
    isOpen: boolean;
    onToggle: () => void;
    verticalToggleIconUrl: string;
    verticalToggleOptions: VerticalToggleOptionMap[];
};

export default function MapVerticalToggle({
    isOpen,
    onToggle,
    verticalToggleIconUrl,
    verticalToggleOptions,
}: MapVerticalToggleProps) {
    const [_clientUrl, setClientUrl] = useClientUrl();

    const onOptionClick = (option: VerticalToggleOptionMap) => {
        // TODO: use router navigate function
        historyReplaceDebounced(option.linkUrl);
        setClientUrl(option.linkUrl);
    };

    return (
        <div
            className={'flex-center right-10 bg-white pointer'}
            data-qa-id={'qa-map-vertical-toggle'}
            onClick={onToggle}
            style={{
                aspectRatio: '1/1',
                borderRadius: '100%',
                width: 36,
            }}
        >
            <div className={'mapVerticalToggleIcon flex-center bg-white'}>
                <IconImage
                    height={20}
                    url={verticalToggleIconUrl}
                    width={20}
                />
            </div>
            {isOpen && (
                <MapTooltip
                    noOptionsText={null}
                    onOptionClick={onOptionClick}
                    options={verticalToggleOptions}
                />
            )}
            <style jsx>
                {`
                    :global(.body--desktop) .mapVerticalToggleIcon {
                        margin: 5px;
                        opacity: 0.75;
                    }
                `}
            </style>
        </div>
    );
}
