import type { IMapMultiPin, IMapPin, IMapPoiIconRecord } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import AccommodationMarker from '@/features/map/map-components/map-markers/accommodation-marker/accommodation-marker';
import DefaultPoiMarker from '@/features/map/map-components/map-markers/default-poi-marker/default-poi-marker';
import MapTooltip from '@/features/map/map-components/map-markers/map-marker-handler/map-tooltip/map-tooltip';
import MultiPinMarker from '@/features/map/map-components/map-markers/multi-pin-marker/multi-pin-marker';
import MapTileSwitchDesktop from '@/features/map/map-components/map-tiles/map-tile-switch-desktop';
import { useMapDataActiveTileState, useMapHoveredPoiKey } from '@/features/map/map-container/map-state';

type MarkerSwitchProps = {
    activePoiKey: null | string;
    icons: IMapPoiIconRecord | null;
    isActive: boolean;
    isDesktopView: boolean;
    marker: IMapMultiPin | IMapPin;
    markerKey: string;
    onPoiClick: (event: React.MouseEvent, poiKey: string) => void;
    visited: boolean;
};

export default function MapMarkerSwitch({
    activePoiKey,
    icons,
    isActive,
    isDesktopView,
    marker,
    markerKey,
    onPoiClick,
    visited,
}: MarkerSwitchProps) {
    const mapDataActiveTileState = useMapDataActiveTileState();
    const [hoveredPoiKey, setHoveredPoiKey] = useMapHoveredPoiKey();
    const isMarkerHovered = hoveredPoiKey === markerKey;

    const markerRef = React.useRef<HTMLDivElement | null>(null);
    const overlayRef = React.useRef<HTMLDivElement | null>(null);

    const isMultiPinMarker = marker.markerType === 'multi';
    const icon = isMultiPinMarker
        ? null
        : (icons?.[marker.pinIcon]?.[isActive || isMarkerHovered ? 'active' : 'inactive'] ?? null);
    const secondaryIcon = isMultiPinMarker
        ? null
        : marker.type === 'spot' && marker.secondaryActivityPoiIds?.length > 0
          ? icons?.[marker.pinIcon]?.secondary
          : null;

    const handleClick = (event: React.MouseEvent<Element, MouseEvent>, markerKey: string) => {
        onPoiClick(event, markerKey);
    };

    const handleMouseEnter = () => {
        if (!activePoiKey) {
            setHoveredPoiKey(markerKey);
        }
    };

    const handleMouseLeave = () => {
        setHoveredPoiKey(null);
    };

    return (
        <>
            {marker.type === 'accommodation' ? (
                <AccommodationMarker
                    isActive={isActive}
                    markerRef={markerRef}
                    onPoiClick={(event) => onPoiClick(event, markerKey)}
                    price={marker.price}
                    visited={visited}
                />
            ) : (
                marker.markerType !== 'multi' && (
                    <DefaultPoiMarker
                        icon={icon}
                        markerRef={markerRef}
                        onClick={(event) => handleClick(event, markerKey)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        qaId={`qa-map-marker-default-${marker.type}`}
                        secondaryIcon={secondaryIcon}
                    />
                )
            )}
            {marker.markerType === 'multi' && icons && (
                <MultiPinMarker
                    activePoiKey={activePoiKey}
                    icons={icons}
                    marker={marker}
                    markerRef={markerRef}
                    onClick={onPoiClick}
                />
            )}
            {isActive && isDesktopView && (
                <MapTooltip
                    anchorRef={markerRef}
                    overlayRef={overlayRef}
                    placements={['bottom', 'top', 'left', 'right']}
                    spacing={isMultiPinMarker ? { x: 15, y: 5 } : 5}
                >
                    <div
                        data-qa-id={'qa-map-tile-container'}
                        ref={overlayRef}
                        style={{ width: mapDataActiveTileState?.type === 'accommodation' ? 486 : 'auto' }}
                    >
                        <MapTileSwitchDesktop activeTile={mapDataActiveTileState} />
                    </div>
                </MapTooltip>
            )}
        </>
    );
}
