import type { IMapHighlightTile } from '@/features/map/map-data-v5/map-types';

import { KeyFacts } from '@/core/features';
import MapTileWrapper from '@/features/map/map-components/map-tiles/map-tile-wrapper';

type MapHighlightTileProps = IMapHighlightTile;

export default function MapHighlightTileDesktop({
    iconUrl,
    image,
    keyFactList,
    linkUrl,
    name,
    subtitle,
}: MapHighlightTileProps) {
    return (
        <MapTileWrapper
            cursorStyle={'pointer'}
            iconUrl={iconUrl}
            image={image}
            isDesktopView={true}
            linkType={'internal'}
            linkUrl={linkUrl}
            qaId={'qa-map-highlight-tile-desktop'}
            subtitle={subtitle}
            title={name}
        >
            <div className={'flex-end-vertical height-100 padding-bottom-15 padding-x-15'}>
                <div className={'width-100'}>
                    <KeyFacts
                        fontSize={'font-size-14'}
                        gap={10}
                        iconSize={14}
                        keyFacts={keyFactList}
                        truncation={'oneLine'}
                    />
                </div>
            </div>
        </MapTileWrapper>
    );
}
