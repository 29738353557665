import type { KeyFactsProps } from '@/core/components/key-facts/key-facts';

import React from 'react';

import PlusIcon from '@/assets/icons/plus';
import { IconImage } from '@/core/features';

type KeyFactsActivityProps = {
    fontSize: 12 | 14;
    keyFacts: KeyFactsProps['keyFacts'];
};

const MAX_ACTIVITIES_COUNT = 6;

export default function KeyFactsActivity({ fontSize, keyFacts }: KeyFactsActivityProps) {
    return (
        <div className={`grid-cols-2 gap-8 width-100 ${fontSize === 12 ? 'font-size-12' : 'font-size-14'}`}>
            {keyFacts.map((activity, index) => {
                if (index >= MAX_ACTIVITIES_COUNT) {
                    return null;
                }

                const shouldShowTruncation =
                    keyFacts.length > MAX_ACTIVITIES_COUNT && index === MAX_ACTIVITIES_COUNT - 1;

                if (shouldShowTruncation) {
                    const additionalActivitiesCount = keyFacts.length - MAX_ACTIVITIES_COUNT;

                    return (
                        <div
                            className={'flex gap-10'}
                            key={`${activity.text}-${index}`}
                        >
                            <PlusIcon /> {additionalActivitiesCount} weitere
                        </div>
                    );
                }

                return (
                    <div
                        className={'width-100 flex gap-10 overflow-hidden'}
                        key={`${activity.text}-${index}`}
                    >
                        {activity.iconUrl && (
                            <IconImage
                                className={'shrink-0'}
                                height={fontSize}
                                url={activity.iconUrl}
                                width={fontSize}
                            />
                        )}
                        <span className={'ellipsis'}>{activity.text}</span>
                    </div>
                );
            })}
        </div>
    );
}
