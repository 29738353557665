import React from 'react';

import debounce from 'lodash.debounce';

import CheckMarkIcon from '@/assets/icons/check-mark';
import DeleteXDesktopBoldIcon from '@/assets/icons/delete-x-desktop-bold';
import { IconImage, LoadingSpinnerIcon } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { useAtomValue } from '@/core/features/store/atom-store';
import color from '@/core/features/styles/color';
import {
    useLayerSwitchOverlayOpen,
    useMapLoadingState,
    useMapTravelFormLoading,
} from '@/features/map/map-container/map-state';
import { useAutoLoadMoreSetting } from '@/features/map/map-data-v5/auto-load-more-button-state';
import {
    isMapDataV5ByBoundariesLoadingAtom,
    mapDataV5ByBoundariesAtom,
    useLoadMoreButtonLoading,
} from '@/features/map/map-data-v5/use-map-data-v5';

type MapLoadMoreButtonProps = {
    loadMoreButton: {
        iconUrl: string;
        title: string;
    } | null;
    onLoadMore: () => void;
};

type MapLoadMoreState = 'loadable' | 'loading' | 'none' | 'none_found' | 'success';

export default function MapLoadMoreButton({ loadMoreButton, onLoadMore }: MapLoadMoreButtonProps) {
    const { isDesktopView } = useDeviceoutput();
    const isLoadMoreButtonLoading = useLoadMoreButtonLoading();
    const isMapLoading = useMapLoadingState();
    const [isLayerSwitchOverlayOpen] = useLayerSwitchOverlayOpen();
    const isMapTravelFormLoading = useMapTravelFormLoading();
    const isMapDataByBoundariesLoading = useAtomValue(isMapDataV5ByBoundariesLoadingAtom);
    const isAutoLoadMoreFeatureFlagEnabled = useAutoLoadMoreSetting();

    const { getIconAnimationStateClass, state, text } = useLoadingButtonState(loadMoreButton);

    const onClickLoadMore = () => {
        if (isLoadMoreButtonLoading || isMapLoading) {
            return;
        }
        onLoadMore();
    };

    React.useEffect(() => {
        if (!isAutoLoadMoreFeatureFlagEnabled || isMapDataByBoundariesLoading || !loadMoreButton) {
            return;
        }

        const debouncedOnLoadMore = debounce(onLoadMore, 100);
        debouncedOnLoadMore();

        return () => {
            debouncedOnLoadMore.cancel();
        };
    }, [
        loadMoreButton,
        isAutoLoadMoreFeatureFlagEnabled,
        isLoadMoreButtonLoading,
        isMapDataByBoundariesLoading,
        onLoadMore,
    ]);

    if (isLayerSwitchOverlayOpen || isMapTravelFormLoading) {
        return null;
    }

    if (state.currentState === 'none' && !loadMoreButton) {
        return null;
    }

    return (
        <div className={'flex-center'}>
            <button
                className={`flex align-center padding-left-20 padding-right-20 loadMoreButton ${isDesktopView ? 'loadMoreButtonDesktop' : ''}`}
                data-qa-id={'qa-map-load-more-button'}
                onClick={onClickLoadMore}
            >
                <div className={`iconContainer ${isDesktopView ? 'iconContainerDesktop' : ''}`}>
                    <div className={getIconAnimationStateClass('none_found')}>
                        <DeleteXDesktopBoldIcon
                            color={color('nobel')}
                            height={isDesktopView ? 12 : 8}
                            width={isDesktopView ? 12 : 8}
                        />
                    </div>
                    <div className={getIconAnimationStateClass('loading')}>
                        <LoadingSpinnerIcon
                            color={color('scorpion')}
                            height={isDesktopView ? 15 : 12}
                            width={isDesktopView ? 15 : 12}
                        />
                    </div>
                    <div className={getIconAnimationStateClass('success')}>
                        <CheckMarkIcon
                            color={color('japaneseLaurel')}
                            height={isDesktopView ? 11 : 7}
                            width={isDesktopView ? 15 : 10}
                        />
                    </div>
                    <div className={getIconAnimationStateClass('loadable')}>
                        <IconImage
                            height={isDesktopView ? 15 : 11}
                            url={loadMoreButton?.iconUrl ?? ''}
                            width={isDesktopView ? 15 : 11}
                        />
                    </div>
                </div>
                {text}
            </button>
            <style jsx>{`
                .loadMoreButton {
                    height: 30px;
                    width: 193px;
                    padding: 0 10px;
                    gap: 10px;
                    margin-top: 10px;
                    border-radius: 20px;
                    overflow: hidden;
                    font-size: 12px;
                    background-color: var(--color-white);
                }

                .loadMoreButtonDesktop {
                    position: absolute;
                    top: 15px;
                    height: 40px;
                    width: 271px;
                    padding: 0 20px;
                    gap: 15px;
                    border-radius: 8px;
                    font-size: 14px;
                    color: var(--color-scorpion);
                }

                .iconContainer {
                    position: relative;
                    width: 12px;
                    height: 100%;
                }

                .iconContainerDesktop {
                    width: 15px;
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    transform: translateY(40px);
                }

                .animateStart {
                    transform: translateY(0);
                }

                .animateIn {
                    transform: translateY(0);
                    transition-duration: 0.3s;
                }

                .animateOut {
                    transform: translateY(-40px);
                    transition-duration: 0.3s;
                }
            `}</style>
        </div>
    );
}

function useLoadingButtonState(loadMoreButton: { iconUrl: string; title: string } | null) {
    const [state, setState] = React.useState<{ currentState: MapLoadMoreState; previousState: MapLoadMoreState }>({
        currentState: 'none',
        previousState: 'none',
    });

    const isAutoLoadMoreFeatureFlagEnabled = useAutoLoadMoreSetting();
    const mapDataByBoundaries = useAtomValue(mapDataV5ByBoundariesAtom);
    const isLoadMoreButtonLoading = useLoadMoreButtonLoading();

    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const handleUpdateState = React.useCallback(
        (newState: MapLoadMoreState) => {
            if (newState === state.currentState) {
                return;
            }

            if (['loadable', 'loading'].includes(newState)) {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                return setState((currentState) => {
                    return { currentState: newState, previousState: currentState.currentState };
                });
            }

            // reset animation state
            if (newState === 'none') {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                return setState({ currentState: 'none', previousState: 'none' });
            }

            // handle success state
            if (state.currentState === 'loading' && newState === 'success') {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = setTimeout(() => {
                    return setState({ currentState: 'none', previousState: 'none' });
                }, 1000);
                return setState((currentState) => {
                    return { currentState: 'success', previousState: currentState.currentState };
                });
            }

            // handle none_found state
            if (state.currentState === 'loading' && newState === 'none_found') {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = setTimeout(() => {
                    return setState({ currentState: 'none', previousState: 'none' });
                }, 2000);
                return setState((currentState) => {
                    return { currentState: 'none_found', previousState: currentState.currentState };
                });
            }
        },
        [state],
    );

    // update state depending on livePrice request/response and featureFlag
    React.useEffect(() => {
        if (!isAutoLoadMoreFeatureFlagEnabled && loadMoreButton && state.currentState === 'none') {
            handleUpdateState('loadable');
        }

        if (!loadMoreButton && state.currentState === 'loadable') {
            handleUpdateState('none');
        }

        if (isLoadMoreButtonLoading) {
            handleUpdateState('loading');
            return;
        }

        const accommodationPins = mapDataByBoundaries?.attributes?.sortedPins.accommodationPins;
        if (accommodationPins && accommodationPins.length > 0) {
            handleUpdateState('success');
        } else {
            handleUpdateState('none_found');
        }
    }, [
        handleUpdateState,
        isAutoLoadMoreFeatureFlagEnabled,
        isLoadMoreButtonLoading,
        loadMoreButton,
        mapDataByBoundaries?.attributes?.sortedPins.accommodationPins,
        state.currentState,
    ]);

    const loadingStateContent: Record<MapLoadMoreState, null | string> = {
        loadable: loadMoreButton?.title ?? null,
        loading: 'Unterkünfte werden geladen',
        none: null,
        none_found: 'Keine Unterkünfte verfügbar',
        success: 'Unterkünfte sind geladen',
    } as const;

    const getIconAnimationStateClass = React.useCallback(
        (icon: MapLoadMoreState) => {
            if (icon === state.currentState) {
                return `icon ${state.previousState === 'none' ? 'animateStart' : 'animateIn'}`;
            }

            if (icon === state.previousState) {
                return 'icon animateOut';
            }
            return 'icon';
        },
        [state.currentState, state.previousState],
    );

    return {
        getIconAnimationStateClass,
        state,
        text: loadingStateContent[state.currentState],
        updateState: handleUpdateState,
    };
}
