type ToolTipDirection = 'horizontal' | 'vertical';
export type Placement =
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'center'
    | 'left'
    | 'right'
    | 'top'
    | 'top-left'
    | 'top-right';

export const calculatePlacement = (
    anchor: Element,
    overlay: Element | null,
    placements: Array<Placement>,
    spacing: { x: number; y: number } | number,
): Placement => {
    if (!overlay || !anchor) {
        return placements[0] ?? 'top';
    }

    const clippingContainer = findClippingParent(overlay);

    if (!clippingContainer) {
        return placements[0] ?? 'top';
    }

    const clippingContainerRect = clippingContainer.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    const anchorRect = anchor.getBoundingClientRect();

    let secondBestPlacement: Placement | null = null;

    // find a completely visible placement in preferred order defined by the user
    // if none was found, use second-best placement (with one intersection)
    // eslint-disable-next-line fp/no-loops
    for (const placement of placements) {
        const computedSpacing = getSpacing(spacing, placement);
        const intersect = { anchorRect, containerRect: clippingContainerRect, overlayRect, spacing: computedSpacing };
        const verticalIntersect: Intersect = { ...intersect, direction: 'vertical' };
        const horizontalIntersect: Intersect = { ...intersect, direction: 'horizontal' };

        switch (placement) {
            case 'center': {
                if (intersectsTop(verticalIntersect)) {
                    break;
                }
                if (
                    intersectsLeft(verticalIntersect) ||
                    intersectsRight(verticalIntersect) ||
                    intersectsTop(verticalIntersect) ||
                    intersectsBottom(verticalIntersect)
                ) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
            case 'bottom': {
                if (intersectsBottom(verticalIntersect)) {
                    break;
                }
                if (intersectsLeft(verticalIntersect) || intersectsRight(verticalIntersect)) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
            case 'top': {
                if (intersectsTop(verticalIntersect)) {
                    break;
                }
                if (intersectsLeft(verticalIntersect) || intersectsRight(verticalIntersect)) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
            case 'left': {
                if (intersectsLeft(horizontalIntersect)) {
                    break;
                }
                if (intersectsTop(horizontalIntersect) || intersectsBottom(horizontalIntersect)) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
            case 'right': {
                if (intersectsRight(horizontalIntersect)) {
                    break;
                }
                if (intersectsTop(horizontalIntersect) || intersectsBottom(horizontalIntersect)) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
        }
    }

    // If none of the placements are perfect, then consider edge cases
    const computedSpacing = getSpacing(spacing, placements[0] ?? 'top');
    const intersect = { anchorRect, containerRect: clippingContainerRect, overlayRect, spacing: computedSpacing };
    const verticalIntersect: Intersect = { ...intersect, direction: 'vertical' };
    const horizontalIntersect: Intersect = { ...intersect, direction: 'horizontal' };

    if (intersectsBottom(verticalIntersect) && intersectsLeft(horizontalIntersect)) {
        return 'top-right';
    } else if (intersectsBottom(verticalIntersect) && intersectsRight(horizontalIntersect)) {
        return 'top-left';
    } else if (intersectsTop(verticalIntersect) && intersectsLeft(horizontalIntersect)) {
        return 'bottom-right';
    } else if (intersectsTop(verticalIntersect) && intersectsRight(horizontalIntersect)) {
        return 'bottom-left';
    }

    // If no completely visible placement was found, return the next best placement (with one intersection)
    return secondBestPlacement ?? placements[0] ?? 'top';
};

export const getSpacing = (spacing: { x: number; y: number } | number, placement: Placement): number => {
    if (typeof spacing === 'number') {
        return spacing;
    }

    if (placement === 'bottom' || placement === 'top') {
        return spacing.y;
    }

    return spacing.x;
};

type Intersect = {
    anchorRect: DOMRect;
    containerRect: DOMRect;
    direction: ToolTipDirection;
    overlayRect: DOMRect;
    spacing: number;
};

const intersectsTop = ({ anchorRect, containerRect, direction, overlayRect, spacing }: Intersect): boolean => {
    const isHorizontal = direction === 'horizontal';
    const heightModifier = isHorizontal ? 0.5 : 1;
    const positionOffset = isHorizontal ? anchorRect.height * 0.5 : 0;
    const spacingModifier = isHorizontal ? 0 : 1;
    return (
        anchorRect.y + positionOffset - spacing * spacingModifier - overlayRect.height * heightModifier <
        containerRect.y
    );
};

const intersectsBottom = ({ anchorRect, containerRect, direction, overlayRect, spacing }: Intersect): boolean => {
    const isHorizontal = direction === 'horizontal';
    const heightModifier = isHorizontal ? 0.5 : 1;
    const spacingModifier = isHorizontal ? 0 : 1;
    return (
        anchorRect.y +
            anchorRect.height * heightModifier +
            spacing * spacingModifier +
            overlayRect.height * heightModifier >
        containerRect.y + containerRect.height - 80 // Padding to account for header height
    );
};

const intersectsLeft = ({ anchorRect, containerRect, direction, overlayRect, spacing }: Intersect): boolean => {
    const isVertical = direction === 'vertical';
    const widthModifier = isVertical ? 0.5 : 1;
    const positionOffset = isVertical ? anchorRect.width * 0.5 : 0;
    const spacingModifier = isVertical ? 0 : 1;
    return (
        anchorRect.x + positionOffset - spacing * spacingModifier - overlayRect.width * widthModifier < containerRect.x
    );
};

const intersectsRight = ({ anchorRect, containerRect, direction, overlayRect, spacing }: Intersect): boolean => {
    const isVertical = direction === 'vertical';
    const widthModifier = isVertical ? 0.5 : 1;
    const spacingModifier = isVertical ? 0 : 1;
    return (
        anchorRect.x +
            anchorRect.width * widthModifier +
            spacing * spacingModifier +
            overlayRect.width * widthModifier >
        containerRect.x + containerRect.width
    );
};

const findClippingParent = (element: Element): Element | null => {
    if (getComputedStyle(element).overflow === 'hidden' || element.localName === 'body') {
        return element;
    }

    if (element.parentElement === null) {
        return null;
    }

    return findClippingParent(element.parentElement as Element);
};
