/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';
import { createPortal } from 'react-dom';

import ChevronSolidIcon from '@/assets/icons/chevron-solid';
import { Divider, IconImage } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { getCdnStaticImageUrl } from '@/core/features/image-v2/image-utils';
import color from '@/core/features/styles/color';
import zIndex from '@/core/features/styles/generated/z-index';
import Tooltip from '@/core/features/tooltip/tooltip';

type MapTooltipOption = {
    iconUrl?: string;
    isSelected: boolean;
    title: string;
};

type MapVerticaSwitchTooltipProps<OptionType extends MapTooltipOption> = {
    noOptionsText: null | string;
    onOptionClick: (option: OptionType) => void;
    options: OptionType[];
};

export default function MapTooltip<OptionType extends MapTooltipOption>({
    noOptionsText,
    onOptionClick,
    options,
}: MapVerticaSwitchTooltipProps<OptionType>) {
    const { isDesktopView } = useDeviceoutput();
    const tooltipRef = React.useRef<HTMLDivElement>(null);

    // The onClick event on createPortal is propogated to the parent component's event handler
    return (
        <>
            {createPortal(
                <div
                    className={'fixed bg-transparent'}
                    style={{
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0,
                        zIndex: zIndex('mapTooltipBackdrop'),
                    }}
                />,
                document.body,
            )}
            <div
                className={'mapTooltip relative'}
                ref={tooltipRef}
            >
                <Tooltip
                    content={
                        <TooltipContent
                            noOptionsText={noOptionsText}
                            onOptionClick={onOptionClick}
                            options={options}
                        />
                    }
                    minWidth={235}
                    tippyConfig={{
                        appendTo: () => document.body,
                        arrow: false,
                        className: 'map-tooltip-tippy',
                        offset: isDesktopView ? [-18, 51] : [20, 41],
                        placement: 'right-end',
                    }}
                    visible={true}
                />
                <div
                    className={'mapTooltipArrow absolute'}
                    style={{
                        bottom: -12,
                        opacity: 0.85,
                        transform: 'rotate(-90deg)',
                        zIndex: zIndex('mapTooltipArrow'),
                    }}
                >
                    <ChevronSolidIcon color={color('white')} />
                </div>
                <style
                    global
                    jsx
                >
                    {`
                        .mapTooltipArrow {
                            right: 24px;
                        }
                        :global(.body--desktop) .mapTooltipArrow {
                            right: 33px;
                        }
                        :global(.map-tooltip-tippy.tippy-box) {
                            border-radius: 10px;
                        }
                        :global(.map-tooltip-tippy .tippy-content) {
                            padding: 0;
                        }
                    `}
                </style>
            </div>
        </>
    );
}

const TooltipContent = <OptionType extends MapTooltipOption>({
    noOptionsText,
    onOptionClick,
    options,
}: {
    noOptionsText: null | string;
    onOptionClick: (option: OptionType) => void;
    options: OptionType[];
}) => {
    return (
        <ul
            className={'flex-start-vertical width-100 height-100 padding-left-8 padding-right-8 overflow-hidden'}
            style={{ justifyContent: 'space-around', minHeight: 35 }}
        >
            {options.length === 0 && noOptionsText ? (
                <li className={'padding-top-10 padding-bottom-10 padding-left-5 padding-right-5'}>
                    <span className={'font-size-14'}>{noOptionsText}</span>
                </li>
            ) : (
                options.map((option, index) => (
                    <React.Fragment key={`${option.title}-${index}`}>
                        <li
                            className={
                                'flex-start-horizontal width-100 padding-top-10 padding-bottom-10 padding-left-5 padding-right-5 gap-10 pointer'
                            }
                            data-qa-id={'qa-map-tooltip-option'}
                            onClick={() => onOptionClick(option)}
                        >
                            <span className={'flex-start-horizontal width-100 gap-15'}>
                                {option.iconUrl && (
                                    <IconImage
                                        height={20}
                                        url={option.iconUrl}
                                        width={20}
                                    />
                                )}
                                <span className={'line-clamp-1 font-size-14'}>{option.title}</span>
                            </span>
                            {option.isSelected && (
                                <IconImage
                                    className={'align-self-end'}
                                    height={24}
                                    url={getCdnStaticImageUrl('/static-images/check-mark-blue.svg')}
                                    width={24}
                                />
                            )}
                        </li>
                        {index !== options.length - 1 && (
                            <Divider
                                className={'width-100'}
                                color={'alto'}
                            />
                        )}
                    </React.Fragment>
                ))
            )}
        </ul>
    );
};
