import type { IMapMultiPin, IMapPoiIconRecord } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import IconImage from '@/core/features/image-v2/icon-image';
import PoiClusterMarker from '@/features/map/map-components/map-markers/cluster-marker/poi-cluster-marker';

type MultiPinMarkerProps = {
    activePoiKey: null | string;
    icons: IMapPoiIconRecord;
    marker: IMapMultiPin;
    markerRef?: React.RefObject<HTMLDivElement>;
    onClick: (event: React.MouseEvent, poiKey: string) => void;
};

export default function MultiPinMarker({ activePoiKey, icons, marker, markerRef, onClick }: MultiPinMarkerProps) {
    const { isAnyPinActive, pins } = React.useMemo(() => {
        const pins = Object.entries(marker.pins);
        return { isAnyPinActive: pins.some(([poiKey]) => poiKey === activePoiKey), pins };
    }, [activePoiKey, marker.pins]);

    if (!isAnyPinActive && marker.isCollapsable) {
        const icon = icons?.[marker.pinIcon]?.['inactive'] ?? null;
        const firstPin = Object.entries(marker.pins)[0];

        if (!icon || !firstPin) {
            return null;
        }

        return (
            <PoiClusterMarker
                icon={icon}
                onClick={(event) => onClick(event, firstPin[0])}
                text={pins.length.toString()}
            />
        );
    }

    return (
        <div
            className={'flex-center'}
            ref={markerRef}
            style={{ gap: isAnyPinActive ? '20px' : undefined }}
        >
            {pins.map(([poiKey, poi], index) => {
                const isActive = activePoiKey === poiKey;
                const icon = icons?.[poi.pinIcon]?.[isActive ? 'active' : 'inactive'] ?? null;

                if (!icon) {
                    return null;
                }

                return (
                    <div
                        className={'flex-center pointer'}
                        data-qa-id={'qa-map-multi-marker'}
                        key={`${poi.poiId}-${index}`}
                        onClick={(event) => onClick(event, poiKey)}
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ marginRight: '-16px', zIndex: poi.zPosition }}
                    >
                        <IconImage
                            height={icon.size}
                            url={icon.url}
                            width={icon.size}
                        />
                    </div>
                );
            })}
        </div>
    );
}
