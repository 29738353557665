import type { IMapPoiIcon } from '@/features/map/map-data-v5/map-types';

import { IconImage } from '@/core/features';

interface PinClusterMarkerProps {
    icon: IMapPoiIcon;
    onClick: () => void;
    text: string;
}

export default function SpotClusterMarker({ icon, onClick, text }: PinClusterMarkerProps) {
    // should not happen
    if (!icon || !icon.font) {
        return null;
    }

    return (
        <div
            className={'flex-center pointer'}
            data-qa-id={'qa-map-cluster-marker'}
            onClick={onClick}
        >
            <IconImage
                height={icon.size}
                url={icon.url}
                width={icon.size}
            />
            <span
                className={'bold absolute z-index-1'}
                style={{
                    color: icon.font.color,
                    fontSize: text.length > 2 ? icon.font.size * 0.8 : icon.font.size,
                }}
            >
                {text}
            </span>
        </div>
    );
}
