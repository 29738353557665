import type { IMapPlaceTile } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import { KeyFact, KeyFacts } from '@/core/features';
import MapTileWrapper from '@/features/map/map-components/map-tiles/map-tile-wrapper';

type MapPlaceTileProps = IMapPlaceTile;

export default function MapPlaceTileDesktop({
    iconUrl,
    image,
    keyFactList,
    linkUrl,
    name,
    subtitle,
    twoColumnKeyFacts,
}: MapPlaceTileProps) {
    return (
        <MapTileWrapper
            cursorStyle={'pointer'}
            iconUrl={iconUrl}
            image={image}
            isDesktopView={true}
            linkType={'internal'}
            linkUrl={linkUrl}
            qaId={'qa-map-place-tile-desktop'}
            subtitle={subtitle}
            title={name}
        >
            <div className={'flex-end-vertical height-100 padding-bottom-15 padding-x-15'}>
                <div className={'width-100'}>
                    {twoColumnKeyFacts.length > 0 && (
                        <div className={'margin-bottom-10 grid-cols-2 grid-gap-10'}>
                            {twoColumnKeyFacts.map((keyfact) => {
                                return (
                                    <KeyFact
                                        fontSize={'font-size-14'}
                                        iconSize={14}
                                        iconUrl={keyfact.iconUrl}
                                        info={keyfact.info}
                                        key={`${keyfact.text}-${keyfact.iconUrl}`}
                                        text={keyfact.text}
                                        textAlignment={'start'}
                                        truncation={'ellipsis'}
                                    />
                                );
                            })}
                        </div>
                    )}
                    <KeyFacts
                        fontSize={'font-size-14'}
                        gap={10}
                        iconSize={14}
                        keyFacts={keyFactList}
                        truncation={'oneLine'}
                    />
                </div>
            </div>
        </MapTileWrapper>
    );
}
