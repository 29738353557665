import type { IMapMarker, IMapTile } from '@/features/map/map-data-v5/map-types';

import { atom, useAtomValue } from '@/core/features/store/atom-store';
import {
    mapActivePoiKeyStateAtom,
    mapActiveTileStateAtom,
    mapTilesStateAtom,
} from '@/features/map/map-container/map-state';
import {
    mapDataV5ByBoundariesAtom,
    mapDataV5DefaultAtom,
    multiPinsAtom,
} from '@/features/map/map-data-v5/use-map-data-v5';

export type MapSwiperTile = IMapTile & { markerKey: string };

type MapSwiperState = {
    activeSwiperIndex: number;
    swiperId: string;
    tiles: MapSwiperTile[];
};

const mapDataActiveMarkerStateAtom = atom<IMapMarker | null>((get) => {
    const mapDataDefault = get(mapDataV5DefaultAtom);
    const mapDataByBoundaries = get(mapDataV5ByBoundariesAtom);
    const activePoiKey = get(mapActivePoiKeyStateAtom);
    const multiPins = get(multiPinsAtom);

    const pins = mapDataByBoundaries?.attributes?.pins ?? mapDataDefault?.attributes?.pins;

    if (!pins || !activePoiKey) {
        return null;
    }

    return pins[activePoiKey] ?? multiPins[activePoiKey] ?? null;
});

const mapSwiperStateAtom = atom<MapSwiperState>((get): MapSwiperState => {
    const mapDataByBoundaries = get(mapDataV5ByBoundariesAtom);
    const activeTile = get(mapActiveTileStateAtom);
    const activePoiKey = get(mapActivePoiKeyStateAtom);
    const tiles = get(mapTilesStateAtom);
    const activeMarker = get(mapDataActiveMarkerStateAtom);

    if (activeMarker?.type === 'accommodation') {
        const markerKey = String(activeMarker.poiId);
        const sortedAccommodationPinKeys = mapDataByBoundaries?.attributes?.sortedPins?.accommodationPins;

        if (!tiles || !sortedAccommodationPinKeys || !activeTile) {
            return {
                activeSwiperIndex: -1,
                swiperId: markerKey,
                tiles: [],
            };
        }
        const swiperTiles: MapSwiperTile[] = sortedAccommodationPinKeys.flatMap<MapSwiperTile>((markerKey) => {
            const tile = tiles[markerKey];
            if (!tile) {
                return [];
            }
            return [{ ...tile, markerKey }];
        });
        const activeSwiperIndex = swiperTiles.findIndex(
            (tile) => 'id' in activeTile && 'id' in tile && tile.id === activeTile.id,
        );

        return {
            activeSwiperIndex,
            swiperId: markerKey,
            tiles: swiperTiles,
        };
    }

    if (activeMarker?.markerType === 'poi') {
        const markerKey = String(activeMarker.poiId);
        const sortedSpotPinKeys = mapDataByBoundaries?.attributes?.sortedPins?.[`${activeMarker.type}Pins`] ?? [];
        const secondaryActivityPoiIds = activeMarker?.type === 'spot' ? activeMarker.secondaryActivityPoiIds : [];
        const pinKeys = [...sortedSpotPinKeys, ...secondaryActivityPoiIds];

        if (!tiles || pinKeys.length === 0 || !activeTile || !activePoiKey) {
            return {
                activeSwiperIndex: 0,
                swiperId: markerKey,
                tiles: [],
            };
        }

        const swiperTiles: MapSwiperTile[] = pinKeys.flatMap((pinMarkerKey) => {
            const tile = tiles[pinMarkerKey];
            if (!tile) {
                return [];
            }

            if (
                activeMarker.type === 'place' &&
                (tile.type !== 'place' || tile.activityId !== activeMarker.activityId)
            ) {
                return [];
            }

            return { ...tile, markerKey: pinMarkerKey };
        });

        const activeSwiperIndex = swiperTiles.findIndex((tile) => tile.markerKey === activePoiKey);

        // UPDATE should not be necessary once unifiedResultList is stable
        if (activeMarker?.type === 'spot' && activeMarker.secondaryActivityPoiIds.length > 0) {
            return {
                activeSwiperIndex,
                swiperId: markerKey,
                tiles: [{ ...activeTile, markerKey: activePoiKey }, ...swiperTiles],
            };
        }

        return {
            activeSwiperIndex,
            swiperId: markerKey,
            tiles: swiperTiles,
        };
    }

    if (activeMarker?.markerType === 'multiPolygon') {
        const markerKey = String(activeMarker.poiId);
        const sortedMultiPolygonKeys = mapDataByBoundaries?.attributes?.sortedPins?.cityPins;

        if (!tiles || !sortedMultiPolygonKeys || !activeTile) {
            return {
                activeSwiperIndex: 0,
                swiperId: markerKey,
                tiles: [],
            };
        }

        const swiperTiles: MapSwiperTile[] = sortedMultiPolygonKeys.flatMap<MapSwiperTile>((markerKey) => {
            const tile = tiles[markerKey];
            if (!tile) {
                return [];
            }
            return [{ ...tile, markerKey }];
        });

        const activeSwiperIndex = swiperTiles.findIndex((tile) => tile.markerKey === activePoiKey);

        return {
            activeSwiperIndex,
            swiperId: markerKey,
            tiles: swiperTiles,
        };
    }

    return {
        activeSwiperIndex: -1,
        swiperId: 'null',
        tiles: [],
    };
});

export const useMapSwiperState = () => useAtomValue(mapSwiperStateAtom);
