import type { IMapCluster, IMapMarker, IMapPinsRecord, IMapPoiIconRecord } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import logger from '@/core/features/logger/logger';
import { useFeatureFlagStore } from '@/core/features/store/feature-flags-store';
import CustomGoogleOverlay from '@/features/map/google-map/google-map-custom-overlay';
import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import { ACCOMMODATION_MARKER_HEIGHT } from '@/features/map/map-components/map-markers/accommodation-marker/accommodation-marker';
import MapClusterSwitch from '@/features/map/map-components/map-markers/map-marker-handler/map-cluster-switch/map-cluster-switch';
import MapMarkerSwitch from '@/features/map/map-components/map-markers/map-marker-handler/map-marker-switch/map-marker-switch';
import MultiPolygonMarker from '@/features/map/map-components/map-markers/mutli-polygon-marker/multi-polygon-marker';
import {
    useMapActivePoiKeyState,
    useMapMultiPinState,
    useMapTravelFormLoading,
    useMapVisitedPois,
} from '@/features/map/map-container/map-state';

type MapMarkerHandlerProps = {
    icons: IMapPoiIconRecord | null;
    isDesktopView?: boolean;
    onClusterClick: (marker: IMapCluster) => void;
    onPoiClick: (event: React.MouseEvent, poiKey: string) => void;
    pins: IMapPinsRecord;
};

const validMarkerTypes: IMapMarker['markerType'][] = ['cluster', 'indicator', 'multi', 'poi', 'multiPolygon'] as const;

export default function MapMarkerHandler({
    icons,
    isDesktopView = false,
    onClusterClick,
    onPoiClick,
    pins,
}: MapMarkerHandlerProps) {
    const googleMapInstance = useGoogleMapInstance();
    const [visitedPois] = useMapVisitedPois();
    const activePoiKey = useMapActivePoiKeyState();
    const activeMultiPinKey = useMapMultiPinState();
    const isAccommodationPinsHidden = useMapTravelFormLoading();

    const pinEntries = React.useMemo(() => Object.entries(pins), [pins]);

    const [isMapDebugUIEnabled] = useFeatureFlagStore('isMapDebugUIEnabled');

    if (!googleMapInstance) {
        return null;
    }

    return (
        <>
            {pinEntries.map(([markerKey, marker]) => {
                if (!validMarkerTypes.includes(marker.markerType)) {
                    logger.error(`Unknown marker type in MapMarkerHandler: "${marker.markerType}"`);
                    return;
                }

                if (marker.type === 'accommodation' && isAccommodationPinsHidden) {
                    return;
                }

                const isActivePoi = activePoiKey === markerKey || activeMultiPinKey === markerKey;

                if (marker.markerType === 'multiPolygon') {
                    return (
                        <MultiPolygonMarker
                            isActivePoi={isActivePoi}
                            key={markerKey}
                            marker={marker}
                            markerKey={markerKey}
                            onPoiClick={onPoiClick}
                        />
                    );
                }

                return (
                    <CustomGoogleOverlay
                        disableHighlighting={!!activePoiKey}
                        isActive={isActivePoi}
                        key={markerKey}
                        map={googleMapInstance}
                        offsetTop={getMarkerOffsetTop(marker, icons)}
                        position={{ lat: marker.point.latitude, lng: marker.point.longitude }}
                        zIndex={marker.zPosition}
                    >
                        <style jsx>
                            {`
                                .debugUi {
                                    &:hover:after {
                                        content: '${markerKey}';
                                        background-color: white;
                                        padding: 2px 6px;
                                        position: absolute;
                                        white-space: pre;
                                        top: 100%;
                                        left: 50%;
                                        transform: translate(-50%, 2px);
                                        border-radius: 10px;
                                    }
                                }
                            `}
                        </style>
                        <div
                            className={`text-left flex relative gap-5 align-center cursor-default pointer-auto user-select-none opacity-100 ${isMapDebugUIEnabled ? 'debugUi' : ''}`}
                        >
                            {marker.markerType === 'indicator' || marker.markerType === 'cluster' ? (
                                <MapClusterSwitch
                                    icons={icons}
                                    isActive={isActivePoi}
                                    marker={marker}
                                    onClusterClick={onClusterClick}
                                />
                            ) : (
                                <MapMarkerSwitch
                                    activePoiKey={activePoiKey}
                                    icons={icons}
                                    isActive={isActivePoi}
                                    isDesktopView={isDesktopView}
                                    marker={marker}
                                    markerKey={markerKey}
                                    onPoiClick={onPoiClick}
                                    visited={visitedPois.includes(markerKey)}
                                />
                            )}
                        </div>
                    </CustomGoogleOverlay>
                );
            })}
        </>
    );
}

const getMarkerOffsetTop = (marker: IMapMarker, icons: IMapPoiIconRecord | null): number => {
    if (marker.markerType === 'indicator') {
        return 4;
    }

    if (marker.markerType === 'multi') {
        const icon = Object.values(marker.pins)[0]?.pinIcon;
        return icon && icons ? (icons[icon]?.active.size ?? 0) : 0;
    }

    switch (marker.type) {
        case 'place':
        case 'school':
        case 'highlight':
        case 'spot':
            return icons?.[marker.pinIcon]?.active?.size ?? 0;
        case 'accommodation':
            return ACCOMMODATION_MARKER_HEIGHT;
        case 'city':
            return 0;
    }
};
